/* ---- ---- ---- ------ ---- ---- ---- */
/* ---- ---- ---- Global ---- ---- ---- */
/* ---- ---- ---- ------ ---- ---- ---- */

body {
    background-color: #9336cc !important;
}

p {
    font-size: 18px;
    line-height: 32px;
}

.p-description {
    padding: 40px;
}

@media screen and (max-width: 992px) {
    .p-description {
        text-align: center;
    }
    .project_link {
        justify-content: center;
    }
    .project_github {
        justify-content: center;
    }
}

.btn-global {
    border-color: #090809 !important;
    background-color: #9336cc !important;
    height: 40px !important;
    width: 150px !important;
    margin: 10px;
}
.btn-global:hover {
    background-color: #8430b7 !important;
}
.clickable-text-global {
    color: black !important;
}

.clickable-text-global:hover {
    color: #9336cc !important;
    transition: 0.3s;
}

.clickable-text-global-darktheme {
    color: white !important;
}
.body-darktheme {
    color: white;
}

.background-color {
    background-color: #f8f7f7;
}

.background-color-alternate {
    background-color: white;
}

.background-color-darktheme {
    background-color: #181627;
}

.background-color-alternate-darktheme {
    background-color: #181627;
}

.app-body-color {
    color: black;
}

.app-body-color-darktheme {
    color: white !important;
}
/* ---- ---- ---- ------ ---- ---- ---- */
/* ---- ---- ---- Header ---- ---- ---- */
/* ---- ---- ---- ------ ---- ---- ---- */
.header-color {
    background-color: #f8f7f7;
}
.header-color-darktheme {
    background-color: #181627;
}

.navbar-header {
    padding-top: 30px !important;
}
/* ---- ---- ---- ----- ---- ---- ---- */
/* ---- ---- ---- Title ---- ---- ---- */
/* ---- ---- ---- ----- ---- ---- ---- */
.title {
}
.title-container {
    text-align: center;
}
.title-subTitle {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: bolder;
    padding-bottom: 10px;
}
.title-project {
    font-weight: bold;
    padding-bottom: 20px;
}
/* ---- ---- ---- ------ ---- ---- ---- */
/* ---- ---- ---- Banner ---- ---- ---- */
/* ---- ---- ---- ------ ---- ---- ---- */

.banner-container {
    padding-top: 150px;
    padding-bottom: 200px;
}

#banner-image {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

#banner-text-container {
    text-align: center;
}

/* ---- ---- ---- ----------------- ---- ---- ---- */
/* ---- ---- ---- Portfolio/Project ---- ---- ---- */
/* ---- ---- ---- ----------------- ---- ---- ---- */
#portfolio {
    padding-top: 60px;
    padding-bottom: 100px;
}

.project-container {
    padding-bottom: 4%;
    padding-left: 20%;
    padding-right: 20%;
}

.portfolio-color {
    background-color: white;
}

.portfolio-color-darktheme {
    background-color: #280a24;
}

.project_image {
    width: 100%;
}
.project_description {
}

/* ---- ---- ---- ----- ---- ---- ---- */
/* ---- ---- ---- About ---- ---- ---- */
/* ---- ---- ---- ----- ---- ---- ---- */
#about {
    padding-top: 100px;
    padding-bottom: 100px;
}
.about_container {
    padding-left: 20%;
    padding-right: 20%;
}

.about_image {
    width: 80%;
    display: flex;
    margin: auto;
    border-radius: 10px;
}

/* ---- ---- ---- ------ ---- ---- ---- */
/* ---- ---- ---- Footer ---- ---- ---- */
/* ---- ---- ---- ------ ---- ---- ---- */
#footer {
}

.footer-container {
    margin-top: 60px;
    margin-bottom: 20px;
    text-align: center;
}
